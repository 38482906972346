




import { AmortizationForm } from "@/components/Amortization";
import Vue from "vue";

export default Vue.extend({
  name: "CreatePage",
  components: {
    AmortizationForm,
  },
});
